<template>
  <div>
    <!-- Title Section -->
    <section class="title-section top">
      <div class="title-wrap">
        <!-- TO GLOBAL PROGRAM -->
        <h2>TO GLOBAL PROGRAM</h2>
        <div class="title-desc" v-html="$t('content.programs.toglb.txt01')">
        </div>
      </div>
      <div class="visual-area">
        <img src="@/assets/images/programs/visual-to-global-img.jpg" />
      </div>
    </section>
    <!-- // Title Section -->

    <!-- 프로그램 혜택 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.toglb.txt02')"></h3>
        </div>

        <StaggerTransition
          id="to-global-benefits"
        >
          <ul class="icon-card-list line-type ani-stagger">
            <!-- SPACE -->
            <li>
              <dl class="icon-card space">
                <dt>SPACE</dt>
                <dd v-html="$t('content.programs.toglb.txt03')">
                </dd>
              </dl>
            </li>

            <!-- MARKET CONSULTING -->
            <li>
              <dl class="icon-card portal">
                <dt>MARKET CONSULTING</dt>
                <dd v-html="$t('content.programs.toglb.txt04')">
                </dd>
              </dl>
            </li>

            <!-- MENTORING -->
            <li>
              <dl class="icon-card consulting">
                <dt>MENTORING</dt>
                <dd v-html="$t('content.programs.toglb.txt05')">
                </dd>
              </dl>
            </li>

            <!-- ADMIN -->
            <li>
              <dl class="icon-card admin">
                <dt>ADMIN</dt>
                <dd v-html="$t('content.programs.toglb.txt06')">
                </dd>
              </dl>
            </li>

            <!-- NETWORKING -->
            <li>
              <dl class="icon-card network">
                <dt>NETWORKING</dt>
                <dd v-html="$t('content.programs.toglb.txt07')">
                </dd>
              </dl>
            </li>

            <!-- INVESTMENT -->
            <li>
              <dl class="icon-card investment">
                <dt>INVESTMENT</dt>
                <dd v-html="$t('content.programs.toglb.txt08')">
                </dd>
              </dl>
            </li>
          </ul>
        </StaggerTransition>
      </div>
    </section>
    <!-- // 프로그램 혜택 -->

    <!-- 선발 프로세스 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.toglb.txt09')"></h3>
        </div>
        <ol class="process-list type2">
          <!-- 서류 접수 -->
          <li>
            <dl class="process" data-aos="fade-right">
              <dt>Step 01</dt>
              <dd v-html="$t('content.programs.toglb.txt10')"></dd>
            </dl>
          </li>

          <!-- 1차 서류 심사 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="200">
              <dt>Step 02</dt>
              <dd v-html="$t('content.programs.toglb.txt11')"></dd>
            </dl>
          </li>

          <!-- 2차 서류 심사 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="400">
              <dt>Step 03</dt>
              <dd v-html="$t('content.programs.toglb.txt12')"></dd>
            </dl>
          </li>

          <!-- 선발 발표 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="600">
              <dt>Step 04</dt>
              <dd v-html="$t('content.programs.toglb.txt13')"></dd>
            </dl>
          </li>

          <!-- 해외 진출 지원 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="800">
              <dt>STEP 05</dt>
              <dd v-html="$t('content.programs.toglb.txt14')"></dd>
            </dl>
          </li>
        </ol>
      </div>
    </section>
    <!-- // 선발 프로세스 -->

    <!-- PORTFOLIO -->
    <section class="grey-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>PORTFOLIO</h3>
        </div>

        <!-- PORTFOLIO 탭 -->
        <BaseTab
          ref="alumniSlider"
          :tabValue="currentPortfolioTab"
          :list="portfolioTabList"
          tabtype="small-tab"
          @input="onPortfolioTabChange"
        >
          <template v-slot="Scope">
            <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
          </template>
        </BaseTab>
        <!-- // PORTFOLIO 탭 -->

        <!-- PORTFOLIO 슬라이더 -->
        <div class="card-list-wrap">
          <StaggerTransition
            id="to-global-portfolio"
            :delay="0"
          >
            <BaseSlider
              ref="portfolioSlider"
              :list="chunkedPortfolioList"
              :autoplay="false"
              @change="onPortfolioSliderChange"
            >
              <template v-slot="{ item }">
                <ul class="card-list ani-stagger">
                  <li
                    v-for="(subItem, subIndex) in item.subList"
                    :key="subIndex"
                  >
                    <LogoCard
                      :name="subItem.name"
                      :logo="requireAssetsImage(subItem.image)"
                    />
                  </li>
                </ul>
              </template>
            </BaseSlider>
          </StaggerTransition>
        </div>
        <!-- // PORTFOLIO 슬라이더 -->
      </div>
    </section>
    <!-- // PORTFOLIO -->

    <!-- 프로그램 참여 사례 -->
    <section class="case-section">
      <div class="inner-lg symbol-type3">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.toglb.txt15')"></h3>
        </div>
        <div class="case-list-wrap">
          <ul class="case-list">
            <!-- 프로그램 참여 사례 1 : 센트비 -->
            <li>
              <div class="case">
                <div class="case-photo-area shadow-type" data-aos="fade-right">
                  <img
                    src="@/assets/images/programs/sentbe-logo-8.png"
                    class="case-photo"
                    alt="센트비"
                  />
                </div>
                <div data-aos="fade-left">
                  <h4 v-html="$t('content.programs.toglb.txt16')"></h4>
                  <span class="participant" v-html="$t('content.programs.toglb.txt17')"></span>
                  <p class="case-review" v-html="$t('content.programs.toglb.txt18')">
                  </p>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 1 : 센트비 -->

            <!-- 프로그램 참여 사례 2 : 모바일닥터 -->
            <li>
              <div class="case">
                <div class="case-photo-area shadow-type" data-aos="fade-left">
                  <img
                    src="@/assets/images/programs/mobile-doctor-logo-8.png"
                    class="case-photo"
                    alt="모바일닥터"
                  />
                </div>
                <div data-aos="fade-right">
                  <h4 v-html="$t('content.programs.toglb.txt19')"></h4>
                  <span class="participant" v-html="$t('content.programs.toglb.txt20')"></span>
                  <p class="case-review" v-html="$t('content.programs.toglb.txt21')">
                  </p>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 2 : 모바일닥터 -->

            <!-- 프로그램 참여 사례 3 : 한국NFC -->
            <li>
              <div class="case">
                <div class="case-photo-area shadow-type" data-aos="fade-right">
                  <img
                    src="@/assets/images/programs/nfc-logo-8.png"
                    class="case-photo"
                    alt="한국NFC "
                  />
                </div>
                <div data-aos="fade-left">
                  <h4 v-html="$t('content.programs.toglb.txt22')"></h4>
                  <span class="participant" v-html="$t('content.programs.toglb.txt23')"></span>
                  <p class="case-review" v-html="$t('content.programs.toglb.txt24')">
                  </p>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 3 : 한국NFC -->

          </ul>
        </div>
      </div>
    </section>
    <!-- // 프로그램 참여 사례 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseTab from '@/components/base/BaseTab.vue';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import LogoCard from '@/components/card/LogoCard.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import _ from 'lodash';

/**
 * Programs > To Global
 */
export default {
  name: 'ProgramsToGlobal',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    BaseTab,
    BaseSlider,
    LogoCard,
    StaggerTransition,
  },

  data() {
    return {
      /**
       * PORTFOLIO 리스트
       * @type {array}
       */
      portfolioList: [
        // 중국
        {
          name: this.$t('content.programs.toglb.txt25'),
          subList: [
            { name: '라이브케어', image: 'programs/portfolio/china/partners-program-china-01.png' },
            { name: '럭스보로', image: 'programs/portfolio/china/partners-program-china-02.png' },
            { name: '리스케이프', image: 'programs/portfolio/china/partners-program-china-03.png' },
            { name: '바우드', image: 'programs/portfolio/china/partners-program-china-04.png' },
            { name: '웨이웨어러블', image: 'programs/portfolio/china/partners-program-china-05.png' },
            { name: '크리에이티브밤', image: 'programs/portfolio/china/partners-program-china-06.png' },
            { name: '키즈팩토리', image: 'programs/portfolio/china/partners-program-china-07.png' },
            { name: '해빛', image: 'programs/portfolio/china/partners-program-china-08.png' },
            { name: '레지', image: 'programs/portfolio/china/partners-program-china-09.png' },
            { name: '마리몬드', image: 'programs/portfolio/china/partners-program-china-10.png' },
            { name: '위바이인터내셔널', image: 'programs/portfolio/china/partners-program-china-11.png' },
            { name: '파우컴퍼니', image: 'programs/portfolio/china/partners-program-china-12.png' },
          ],
        },
        // 베트남
        {
          name: this.$t('content.programs.toglb.txt26'),
          subList: [
            { name: '동동', image: 'programs/portfolio/vietnam/partners-program-vietnam-01.png' },
            { name: '모바일닥터', image: 'programs/portfolio/vietnam/partners-program-vietnam-02.png' },
            { name: '선소프트웨어', image: 'programs/portfolio/vietnam/partners-program-vietnam-03.png' },
            { name: '센트비', image: 'programs/portfolio/vietnam/partners-program-vietnam-04.png' },
            { name: '아토이', image: 'programs/portfolio/vietnam/partners-program-vietnam-05.png' },
            { name: '위닝아이', image: 'programs/portfolio/vietnam/partners-program-vietnam-06.png' },
            { name: '한국NFC', image: 'programs/portfolio/vietnam/partners-program-vietnam-07.png' },
            { name: '글루가', image: 'programs/portfolio/vietnam/partners-program-vietnam-08.png' },
            { name: '인데이터랩', image: 'programs/portfolio/vietnam/partners-program-vietnam-09.png' },
            { name: '지텐션', image: 'programs/portfolio/vietnam/partners-program-vietnam-10.png' },
            { name: '투미유', image: 'programs/portfolio/vietnam/partners-program-vietnam-11.png' },
            { name: '프릭엔', image: 'programs/portfolio/vietnam/partners-program-vietnam-12.png' },
            { name: '휴먼스케이프', image: 'programs/portfolio/vietnam/partners-program-vietnam-13.png' },
          ],
        },
        // 일본
        {
          name: this.$t('content.programs.toglb.txt27'),
          subList: [
            { name: '디오션', image: 'programs/portfolio/japan/partners-program-japan-01.png' },
            { name: '사운드그래프', image: 'programs/portfolio/japan/partners-program-japan-02.png' },
            { name: '캐쉬멜로', image: 'programs/portfolio/japan/partners-program-japan-03.png' },
            { name: '하이보이스', image: 'programs/portfolio/japan/partners-program-japan-04.png' },
            { name: '스마트엠디', image: 'programs/portfolio/japan/partners-program-japan-05.png' },
            { name: '쎈스톤', image: 'programs/portfolio/japan/partners-program-japan-06.png' },
            { name: '지속가능발전소', image: 'programs/portfolio/japan/partners-program-japan-07.png' },
          ],
        },
        // 인도네시아
        {
          name: this.$t('content.programs.toglb.txt28'),
          subList: [
            { name: '모바일닥터', image: 'programs/portfolio/indonesia/partners-program-indonesia-01.png' },
          ],
        },
      ],

      /**
       * 현재 PORTFOLIO 탭
       * @type {number}
       */
      currentPortfolioTab: 0,
    };
  },

  computed: {
    /**
     * PORTFOLIO 탭 리스트
     * @type {string[]}
     */
    portfolioTabList() {
      return this.portfolioList.map((item) => `${item.name} (${item.subList.length})`);
    },

    /**
     * 분할된 PORTFOLIO 리스트 (12개 단위로 분할)
     * @type {array}
     */
    chunkedPortfolioList() {
      return this.portfolioList.reduce((list, item, index) => list.concat(
        _.chunk(item.subList, 12)
          .map((chunkedSubList) => ({
            name: item.name,
            subList: chunkedSubList,
            tabIndex: index,
          })),
      ), []);
    },
  },

  methods: {
    /**
     * PORTFOLIO 탭 선택 이벤트 처리
     * @param {number} tabIndex
     */
    onPortfolioTabChange(tabIndex) {
      if (tabIndex >= 0) {
        const slideIndex = this.chunkedPortfolioList
          .findIndex((item) => item.tabIndex === tabIndex);
        this.$refs.portfolioSlider.slideTo(slideIndex);
      }
    },

    /**
     * PORTFOLIO 슬라이더 변경 이벤트 처리
     * @param {number} slideIndex
     */
    onPortfolioSliderChange(slideIndex) {
      const { tabIndex } = this.chunkedPortfolioList[slideIndex];
      this.currentPortfolioTab = tabIndex;
    },
  },
};
</script>
