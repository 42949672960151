<template>
  <div
    class="card"
  >
    <img
      v-if="logo"
      :src="logo"
      :alt="name"
    />
    <span
      :class="this.logo ? 'blind' : 'card-logo-text'"
    >{{ name }}</span>
  </div>
</template>

<script>
/**
 * 로고 Card 컴포넌트
 */
export default {
  name: 'LogoCard',
  props: {
    /**
     * 로고 이름
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * 로고 이미지
     */
    logo: {
      type: [String, Object],
    },
  },
};
</script>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<div class="card-list-wrap">
  <ul class="card-list">
    <li>
      <LogoCard
        name="현대자동차"
        :logo="requireAssetsImage('programs/partner/withpartner-1.png')"
      />

      <LogoCard
        name="기아자동차"
      />
    </li>
  </ul>
</div>
```
</docs>
